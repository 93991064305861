// extracted by mini-css-extract-plugin
export var actionBar = "BlogPostCategoriesDashboard__actionBar__HUTC8";
export var actionBarDropdown = "BlogPostCategoriesDashboard__actionBarDropdown__8XHog";
export var actionBarError = "BlogPostCategoriesDashboard__actionBarError__WQsWS";
export var actionBarInfo = "BlogPostCategoriesDashboard__actionBarInfo__hvDFu";
export var actionBarInner = "BlogPostCategoriesDashboard__actionBarInner__N6jbn";
export var actionBarSpinner = "BlogPostCategoriesDashboard__actionBarSpinner__SUGhQ";
export var addNewProductButton = "BlogPostCategoriesDashboard__addNewProductButton__cLoJ_";
export var badge = "BlogPostCategoriesDashboard__badge__xp1GW";
export var blogPostCategory = "BlogPostCategoriesDashboard__blogPostCategory__uSiZ1";
export var blogPostCategoryMenu = "BlogPostCategoriesDashboard__blogPostCategoryMenu__yqsxk";
export var blogPostCount = "BlogPostCategoriesDashboard__blogPostCount__X7giy";
export var column = "BlogPostCategoriesDashboard__column___Bev9";
export var controlRightAlign = "BlogPostCategoriesDashboard__controlRightAlign__PEem7";
export var createdAt = "BlogPostCategoriesDashboard__createdAt__QhenN";
export var deleteConfirmationButtons = "BlogPostCategoriesDashboard__deleteConfirmationButtons__rqwIu";
export var deleteConfirmationDialog = "BlogPostCategoriesDashboard__deleteConfirmationDialog__tbm3t";
export var deleteConfirmationMessage = "BlogPostCategoriesDashboard__deleteConfirmationMessage__ieD7U";
export var deleteConfirmationOverlay = "BlogPostCategoriesDashboard__deleteConfirmationOverlay__XUlnj";
export var deleteConfirmationText = "BlogPostCategoriesDashboard__deleteConfirmationText__PdgOu";
export var dropdown = "BlogPostCategoriesDashboard__dropdown__yhPDv";
export var dropdownBody = "BlogPostCategoriesDashboard__dropdownBody__HVsR0";
export var dropdownBodyLink = "BlogPostCategoriesDashboard__dropdownBodyLink__dTo9S";
export var dropdownBodyRow = "BlogPostCategoriesDashboard__dropdownBodyRow__SbtR9";
export var fetchError = "BlogPostCategoriesDashboard__fetchError__AMhDA";
export var flex = "BlogPostCategoriesDashboard__flex__U62AU";
export var flexColumn = "BlogPostCategoriesDashboard__flexColumn__zYryC";
export var gap1 = "BlogPostCategoriesDashboard__gap1__vhHtu";
export var gap2 = "BlogPostCategoriesDashboard__gap2__CX9jV";
export var gap3 = "BlogPostCategoriesDashboard__gap3__gqawO";
export var gap4 = "BlogPostCategoriesDashboard__gap4__p0Rkx";
export var gap5 = "BlogPostCategoriesDashboard__gap5__T6tqy";
export var gridColumns = "BlogPostCategoriesDashboard__gridColumns__zYpoC";
export var header = "BlogPostCategoriesDashboard__header__G3Mrb";
export var headerControls = "BlogPostCategoriesDashboard__headerControls__Wv1g8";
export var hidden = "BlogPostCategoriesDashboard__hidden__vNLIn";
export var label = "BlogPostCategoriesDashboard__label__fi7zj";
export var layout = "BlogPostCategoriesDashboard__layout__ogrWF";
export var pagination = "BlogPostCategoriesDashboard__pagination__fIekL";
export var priority = "BlogPostCategoriesDashboard__priority__X7lf_";
export var protectedValue = "BlogPostCategoriesDashboard__protectedValue__zKjGY";
export var row = "BlogPostCategoriesDashboard__row__Kq4cH";
export var searchField = "BlogPostCategoriesDashboard__searchField__LREi5";
export var shown = "BlogPostCategoriesDashboard__shown__VwS3Z";
export var table = "BlogPostCategoriesDashboard__table__SKehY";
export var tableBody = "BlogPostCategoriesDashboard__tableBody__rjZXW";
export var tableBodyMinHeight = "BlogPostCategoriesDashboard__tableBodyMinHeight__oFd8o";
export var tableHeader = "BlogPostCategoriesDashboard__tableHeader__mfgTd";
export var tableHeaderCell = "BlogPostCategoriesDashboard__tableHeaderCell__IQ2qL";
export var title = "BlogPostCategoriesDashboard__title__mFLOH";
export var updatedAt = "BlogPostCategoriesDashboard__updatedAt__poKEs";